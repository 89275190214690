<template>
  <div v-if="question" class="grid grid-cols-2 gap-2">
    <sqr-input-text
      label="Given name placeholder"
      :value="question.givenNamePlaceholder"
      @change="propSet('givenNamePlaceholder', $event)"
    />
    <sqr-input-text
      label="Family name placeholder"
      :value="question.familyNamePlaceholder"
      @change="propSet('familyNamePlaceholder', $event)"
    />
  </div>
</template>

<script>
import base from '../base';
import SqrInputText from '@/sqrd-ui/SqrInputText';
export default {
  name: 'NameEdit',
  mixins: [base],
  components: { SqrInputText },
};
</script>
